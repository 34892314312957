/** @jsx jsx */

import React, { useState, useEffect, Fragment, useRef } from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import { variables } from '../styles/global'
import renderStructuredText from '../util/StructuredTextToHtml'
import SEO from '../components/Seo'
import { BsArrowLeft } from 'react-icons/bs'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export function TekstPage({ pageContext }: any) {
  return (
    <Layout>
      <SEO title={pageContext.titel} />
      <div
        css={css`
          margin: 0 auto;
          display: flex;
          align-items: center;
          flex-direction: column;
          max-width: 800px;
        `}
      >
        <Link
          to="/woord"
          css={css`
            align-self: start;
            text-decoration: underline;
            margin: -30px 0 20px;
          `}
        >
          <BsArrowLeft
            css={css`
              transform: translateY(3px);
            `}
          />
          Terug naar overzicht
        </Link>
        {pageContext.titel && <h1>{pageContext.titel}</h1>}
        {pageContext.datum && (
          <div
            css={css`
              margin-top: -20px;
            `}
          >
            {pageContext.datum}
          </div>
        )}
        {pageContext.afbeelding?.gatsbyImageData && (
          <GatsbyImage
            image={pageContext.afbeelding.gatsbyImageData}
            alt=""
            css={css`
              margin-top: 30px;
              margin-bottom: 20px;
              max-width: 200px;
              max-height: 200px;
            `}
          />
        )}

        {pageContext.tekstPreview && (
          <div
            dangerouslySetInnerHTML={{
              __html: renderStructuredText(pageContext.volledigeTekst.value),
            }}
          />
        )}
      </div>
    </Layout>
  )
}

export default TekstPage
